import React from 'react';
import { Container } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Path } from '../constants/path';

const useStyles = makeStyles(() =>
  createStyles({
    background: {
      background: '#ECEFF1',
      textAlign: 'center'
    },
    container: {
      margin: 0,
      padding: '10px 15px'
    },
    linkTitle: {
      fontSize: '12px',
      lineHeight: '17px',
      color: '#828282',
      margin: '0 12px'
    }
  })
);

const Footer: React.FC = () => {
  const styles = useStyles({});
  return (
    <div className={styles.background}>
      <Container className={styles.container}>
        <a
          className={styles.linkTitle}
          style={{ textDecoration: 'none' }}
          href="https://www.cdedirect.co.jp/pdf/sumamoruchintai_site-policy_202309.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          サイトポリシー
        </a>
        <a
          className={styles.linkTitle}
          style={{ textDecoration: 'none' }}
          href="https://www.cdedirect.co.jp/pdf/privacy.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          CDエナジーダイレクトプライバシーポリシー
        </a>
        <br />
        <a
          className={styles.linkTitle}
          style={{ textDecoration: 'none' }}
          href="https://www.cdedirect.co.jp/corpo/specified_commercial_transactions.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          特商法に基づく表記
        </a>
        <a
          className={styles.linkTitle}
          style={{ textDecoration: 'none' }}
          href="https://bitkey.co.jp/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          bitkeyプライバシーポリシー
        </a>
      </Container>
    </div>
  );
};

export default Footer;
