enum ActionType {
  startToFetchProperty = 'START_TO_FETCH_PROPERTY',
  setProperty = 'SET_PROPERTY',
  failedSetProperty = 'FAILED_SET_PROPERTY',
  setAllSpaceId = 'SET_ALL_SPACE_ID',
  setContractId = 'SET_CONTRACT_ID'
}

export type PropertyStatus = 'not_load' | 'property_loading' | 'set' | 'error';

export interface Property {
  allSpaceId: string;
  contractId: string;
  applicationStatus: string;
  hasElectricContract: boolean;
  occupyScheduledDate: Date;
  postCode: string;
  address: string;
  prefecture: string;
  city: string;
  afterCity: string;
  buildingName: string;
  propertyName: string;
  propertyCode: string;
  sumamoruCode: string;
  sumamoruPlan?: string;
  status: string;
  managementCompanyAddress: string;
  managementCompanyName: string;
  managementCompanyPhoneNumber: string;
  sumamoruInstallAppTypes: Array<string>;
  sumamoruProjectType?: SumamoruProjectType;
}

export enum SumamoruInstallAppType {
  bitlock = 'bitlock',
  homehub = 'homehub'
}

/**
 * スマモル賃貸サービスの種類です。
 *
 * OG域内: 大阪ガス様の管理内のうち関西圏内に適用可能なプラン
 * OG域内 電気のみ: 大阪ガス様の管理内のうち関西圏外に適用可能なプランで、電気のみのプラン
 * OG域外: 大阪ガス様の管理内のうち関西圏外(中部九州)に適用可能なプラン
 * CDE: CDE様の管理内に適用可能なプラン
 * CDE 電気のみ: CDE様の管理内に適用可能なプランで、電気のみのプラン
 * スマモルでない通常物件: スマモル組織においてスマモル賃貸サービスを適用しないプラン
 *
 * 以下は2024/04時点で運用されていないプラン
 * 京葉ガス: 京葉ガス様の管理内に適用可能なプラン
 * 西部ガス: 西部ガス様の管理内に適用可能なプラン
 *
 * bitlock-common-serviceにもenum値が定義されています。
 * @see https://github.com/bitkey-service/bitlock-common-service/blob/43cf22854a845ffe57a692eed6c95d06a14234e4/src/business-types/residence/organizations/add-ons/sumamoru-project-type.ts
 *
 */
export enum SumamoruProjectType {
  OGInternal = 'OGInternal', // OG域内
  OGInternalOnlyElectricity = 'OGInternal_OnlyElectricity', // OG域内 電気のみ
  OGExternal = 'OGExternal', // OG域外
  CDE = 'CDE', // CDE
  CDEOnlyElectricity = 'CDE_OnlyElectricity', // CDE 電気のみ
  KeiyouGas = 'KeiyouGas', // 京葉ガス
  SaibuGas = 'SaibuGas', // 西武ガス
  NotSumamoru = 'NotSumamoru' // スマモルでない通常物件
}

export enum SumamoruPlan {
  OG_Default = 'OG_Default',
  OG_Viewn = 'OG_Viewn'
}

export default ActionType;
