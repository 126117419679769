import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    applyHeading: {
      fontSize: 20,
      lineHeight: '29px',
      color: 'var(--color-gray-2)',
      letterSpacing: '0.5px',
      margin: '24px 0 0',
      whiteSpace: 'pre-wrap'
    },
    applyLabel: {
      margin: '20px 0 0',
      fontSize: 12,
      color: 'var(--color-gray-3)'
    },
    applyCaption: {
      fontSize: 12,
      color: 'var(--color-gray-3)',
      margin: '6px 0 0'
    },
    accountCaption: {
      color: 'var(--color-text)',
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      margin: ' 16px 0 0'
    },
    accountBottomGuide: {
      fontSize: 14,
      color: 'var(--color-text)',
      margin: '34px 0 0',
      textAlign: 'center'
    },
    accountFormHeading: {
      color: 'var(--color-gray-2)',
      fontWeight: 500,
      fontStyle: 'normal',
      fontSize: 16,
      textAlign: 'center',
      margin: 0
    }
  })
);

export const ApplyHeading = (props: { text: string; id?: string }) => {
  const styles = useStyles({});
  return (
    <h1 id={props.id} className={styles.applyHeading}>
      {props.text}
    </h1>
  );
};

export const ApplyLabel = (props: { text: string }) => {
  const styles = useStyles({});
  return <h2 className={styles.applyLabel}>{props.text}</h2>;
};

export const ApplyCaption = (props: { text: string }) => {
  const styles = useStyles({});
  return <p className={styles.applyCaption}>{props.text}</p>;
};

export const AccountCaption = (props: { text: string }) => {
  const styles = useStyles({});
  return <p className={styles.accountCaption}>{props.text}</p>;
};

export const AccountBottomGuide = (props: { text: string }) => {
  const styles = useStyles({});
  return <p className={styles.accountBottomGuide}>{props.text}</p>;
};

export const AccountFormHeading = (props: { text: string }) => {
  const styles = useStyles({});
  return <h2 className={styles.accountFormHeading}>{props.text}</h2>;
};
