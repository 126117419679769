export enum FixedValueKey {
  ProjectId = 'projectId',

  // 認証関連
  RefreshToken = 'refreshToken',

  // bMからURL発行時点で確定している情報
  OrganizationId = 'organizationId',
  AllSpaceId = 'allSpaceId',
  ContractId = 'contractId',
  BuildingName = 'buildingName',
  PostCode = 'postCode',
  Prefecture = 'prefecture',
  City = 'city',
  AfterCity = 'afterCity',
  Address1 = 'address1',
  Address2 = 'address2',
  PropertyServiceCode = 'propertyServiceCode',
  PropertyName = 'propertyName',
  PropertyCode = 'propertyCode',
  OccupyScheduledDate = 'occupyScheduledDate',
  HasElectricContract = 'hasElectricContract',
  SumamoruProjectType = 'sumamoruProjectType',
  SumamoruPlan = 'sumamoruPlan',

  BkpUserId = 'bkpUserId',
  BkpEmail = 'bkpEmail',
  BkpPhoneNumber = 'bkpPhoneNumber'
}
