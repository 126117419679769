import * as React from 'react';
import { useMemo, useContext } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ConfirmationDisplayType } from '../../enums/confirmation/confirmation-display-type';
import { ContainerElement } from '../../types/common/item-definition-types';
import { Table } from '../../components/Table';
import { ApplyHeading } from '../../components/Label';
import { Path } from '../../constants/path';
import { ConfimationFormStateContext } from '../template/ConfirmTemplate';
import ConfirmationComponentHandler from './ConfirmationComponentHandler';
import { checkComponentElementCondition } from '../utils/check-component-element-condition';

interface P {
  container: ContainerElement;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: '0 0 16px',
      position: 'relative'
    },
    editButton: {
      color: 'var(--color-key)',
      position: 'absolute',
      fontSize: 14,
      top: 4,
      right: 0
    }
  })
);

// Containerのリストを確認画面用によろしく表示するためにハンドリングする関数
const ConfirmationFormContainerHandler: React.FC<P> = ({ container }) => {
  const styles = useStyles({});

  const { inputValues, applicationParams } = useContext(ConfimationFormStateContext);

  const useEmpasizeDisableInput = useMemo(
    () => container.confirmationDisplayType === ConfirmationDisplayType.EmphasizedDisableInput,
    []
  );
  const useNormalListView = useMemo(
    () =>
      !container.confirmationDisplayType || container.confirmationDisplayType === ConfirmationDisplayType.NormalList,
    []
  );

  const isDisplay = useMemo(
    () =>
      checkComponentElementCondition({
        conditions: container.isDisplayCondition,
        inputValues: inputValues,
        applicationParams: applicationParams,
        initialValue: true
      }),
    [inputValues, applicationParams]
  );

  const title = useMemo(() => container.confirmationTitle || container.title, []);
  return (
    <>
      {isDisplay && (
        <Container className={styles.container}>
          {!!title && title.length > 0 && <ApplyHeading text={title} />}
          {useEmpasizeDisableInput &&
            container.components &&
            container.components.map(component => (
              <ConfirmationComponentHandler
                component={component}
                confirmationDisplayType={container.confirmationDisplayType}
              />
            ))}
          {useNormalListView && (
            <Table>
              <>
                {container.components &&
                  container.components.map(component => (
                    <ConfirmationComponentHandler
                      component={component}
                      confirmationDisplayType={container.confirmationDisplayType}
                    />
                  ))}
              </>
            </Table>
          )}
          <Link to={`${Path.application.url}#${container.id}`}>
            <div className={styles.editButton}>変更</div>
          </Link>
        </Container>
      )}
    </>
  );
};

export default React.memo(ConfirmationFormContainerHandler);
