import { InputComponentSelectionItem } from '../../types/common/item-definition-types';
import { dateDisplayFormat } from '../../config/item-definitions';
import * as moment from 'moment-timezone';
import { ValueOriginType } from '../../enums/common/value-origin-type';
import { ComponentType } from '../../enums/common/component-type';

export const getConfirmationInputText = (param: {
  inputValues: any;
  applicationParams: any;
  inputValueType?: ValueOriginType;
  valueKey: any;
  initialValue?: any;
  converter?: (value: any) => any;
  inputComponentType: ComponentType;
  selectionItems?: Array<InputComponentSelectionItem>;
}): string => {
  const inputValue = getComponentElementKeyValue(param);

  const { inputComponentType, selectionItems } = param;

  if (!inputComponentType) {
    return inputValue;
  }

  switch (inputComponentType) {
    case ComponentType.RadioButtonInput:
    case ComponentType.SelectItemInput:
      const item = selectionItems && selectionItems.find(item => item.value === inputValue);
      if (!item) {
        return inputValue;
      }
      return item.label;
    case ComponentType.KeyboardDateInput:
      return moment(inputValue).format(dateDisplayFormat);
    case ComponentType.SelectDateInput:
      return moment(inputValue).format('YYYY年MM月DD日');
    case ComponentType.TextFieldInput:
      return inputValue;
    default:
      return inputValue;
  }
};

export const getComponentElementKeyValue = (param: {
  inputValues: any;
  applicationParams: any;
  inputValueType?: ValueOriginType;
  valueKey: any;
  initialValue?: any;
  converter?: (value: any) => any;
}) => {
  const { inputValues, applicationParams, inputValueType, valueKey, initialValue, converter } = param;

  if (inputValueType === ValueOriginType.Fixed) {
    return convertComponentElementKeyValue(valueKey, converter);
  } else if (inputValueType === ValueOriginType.ApplicationParams) {
    return convertComponentElementKeyValue(applicationParams[valueKey] || initialValue, converter);
  } else {
    return convertComponentElementKeyValue(inputValues[valueKey] || initialValue, converter);
  }
};

const convertComponentElementKeyValue = (value: any, converter?: (value: any) => any): any => {
  if (!converter) {
    return value;
  }
  return converter(value);
};
