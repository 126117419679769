import * as React from 'react';
import { useCallback, useMemo, useState, useContext, useEffect } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import * as moment from 'moment-timezone';
import FormattedMomentUtils from '../utils/formatted-moment-utils';
import { getComponentElementKeyValue } from '../utils/key-value-getter';
import { BaseFormStateContext } from '../BaseForm';
import { ComponentInputElement } from '../../types/common/item-definition-types';
import { ItemValueKeySuffix } from '../../enums/common/item-value-key-suffix';
import DateUtils from '../../utils/date-utils';
import { checkComponentElementCondition } from '../utils/check-component-element-condition';
import { basicSiteVariablesDefinition } from '../../config/baseConfig';

interface P {
  componentElement: ComponentInputElement;
}

const useStyles = makeStyles(
  createStyles({
    calendar: {
      '& .MuiPickersDay-daySelected': {
        color: 'white'
      }
    }
  })
);

const FixedTextElement: React.FC<P> = ({ componentElement }) => {
  const styles = useStyles({});
  const { inputValues, updateInputValues, applicationParams } = useContext(BaseFormStateContext);

  const valueKey = useMemo(() => componentElement.valueKey, []);
  const valueHasErrorKey = useMemo(() => valueKey + ItemValueKeySuffix.HasError, []);

  const today: string = useMemo(() => DateUtils.strToday(), []);

  const minDate: string | undefined = useMemo(() => {
    if (!componentElement.minDateOffset) {
      return undefined;
    } else if (componentElement.useBusinessDayOffset) {
      return DateUtils.businessDaysToTargetDate(componentElement.minDateOffset);
    } else {
      return DateUtils.offsetDaysToTargetDate(componentElement.minDateOffset);
    }
  }, [componentElement.minDateOffset, componentElement.useBusinessDayOffset]);

  const maxDate: string | undefined = useMemo(() => {
    if (!componentElement.maxDateOffset) {
      return undefined;
    } else if (componentElement.useBusinessDayOffset) {
      return DateUtils.businessDaysToTargetDate(componentElement.maxDateOffset);
    } else {
      return DateUtils.offsetDaysToTargetDate(componentElement.maxDateOffset);
    }
  }, [componentElement.maxDateOffset, componentElement.useBusinessDayOffset]);

  // 初期値の更新
  // 更新時に入れている型を考慮してあげる必要あるかも
  useEffect(() => {
    if (!minDate) {
      updateInputValues(valueKey, today);
    } else if (moment(minDate).isAfter(today)) {
      updateInputValues(valueKey, minDate);
    } else {
      updateInputValues(valueKey, today);
    }
  }, []);

  const inputValue = useMemo(
    () =>
      getComponentElementKeyValue({
        inputValues,
        applicationParams,
        inputValueType: componentElement.valueType,
        valueKey: valueKey,
        initialValue: componentElement.initialValue,
        converter: componentElement.converter
      }) ||
      minDate ||
      today,
    [
      inputValues[componentElement.valueKey],
      applicationParams[componentElement.valueKey],
      componentElement.valueType,
      valueKey,
      componentElement.initialValue
    ]
  );

  const [errorMessage, setErrorMessage] = useState('');

  const handleDateChange = useCallback(
    e => updateInputValues(valueKey, e && e.format(basicSiteVariablesDefinition.linkageDateFormat)),
    [valueKey]
  );

  const handleOnError = useCallback(() => {
    let error = true;
    if (!inputValue || inputValue == 'Invalid date') {
      setErrorMessage('有効な日付を入力してください。');
      updateInputValues(`${valueKey}HasError`, true);
    } else if (minDate && moment(inputValue).isBefore(minDate)) {
      setErrorMessage(`${minDate}以降の日付を入力してください`);
      updateInputValues(`${valueKey}HasError`, true);
    } else if (maxDate && moment(inputValue).isAfter(maxDate)) {
      setErrorMessage(`${maxDate}以前の日付を入力してください`);
      updateInputValues(`${valueKey}HasError`, true);
    } else {
      error = false;
      setErrorMessage(``);
      updateInputValues(`${valueKey}HasError`, false);
    }
    updateInputValues(valueHasErrorKey, error);
  }, [minDate, maxDate, inputValue]);

  return (
    <MuiPickersUtilsProvider utils={FormattedMomentUtils}>
      <KeyboardDatePicker
        margin="normal"
        color="primary"
        className={styles.calendar}
        id={`date-picker-dialog-${valueKey}`}
        format="YYYY/MM/DD"
        helperText={errorMessage}
        value={inputValue === '' ? null : inputValue}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          'aria-label': 'change date'
        }}
        cancelLabel={<span style={{ color: 'black' }}>キャンセル</span>}
        error={!!errorMessage}
        onError={handleOnError}
        disableToolbar
        style={{ width: '100%' }}
        inputVariant="outlined"
        minDate={minDate}
        maxDate={maxDate}
      />
    </MuiPickersUtilsProvider>
  );
};

export default React.memo(FixedTextElement);
