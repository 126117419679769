import React, { useEffect } from 'react';
import BaseStepper from '../BaseStepper';
import { BaseFab } from '../BaseFab';
import { Path } from '../../constants/path';
import OccupancyLabel from '../OccupancyLabel';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { mapStateToProps, mapDispatchToProps } from '../../container/index';
import { useHistory } from 'react-router-dom';
import { Loading } from '../atoms/Loading';
import ApplyCompleteScreen from '../../screens/application/ApplyCompleteScreen';
import Footer from '../Footer';
import * as moment from 'moment-timezone';
import NotFoundTemplate from '../../screens/others/NotFound';

const displayPlanLabel = '電気契約申し込み';
const displayPlanName = 'CDエナジーの電気(スマモル賃貸プラン)に申し込む';

const displayNameDescriptions = [
  'ご入居予定の物件には、CDエナジーの電気「スマモル賃貸プラン」が標準採用されています。'
];
const displayNameCautions = ['スマモルプランに申し込まない場合は、物件の管理会社へご連絡ください。'];

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      background: 'var(--color-key)',
      minHeight: '100vh',
      textAlign: 'center',
      '& h1': {
        margin: 0,
        paddingTop: 20,
        color: 'var(--color-white)',
        fontSize: 16,
        letterSpacing: '0.08em',
        fontWeight: 'bold'
      },
      '& p': {
        margin: '24px 0 0',
        color: 'var(--color-white)',
        fontSize: 16,
        fontWeight: 500
      },
      '@media (min-width:768px)': {
        minHeight: 745,
        borderRadius: 10
      }
    },
    loadingContainer: {
      marginTop: 240,
      paddingTop: '50%'
    },

    infoContainer: {
      margin: '0 24px'
    },
    caption: {
      fontSize: 12,
      lineHeight: '17px',
      color: 'var(--color-gray-3)',
      textAlign: 'left',
      '& span': {
        color: '#f29700'
      }
    },
    button: {
      marginTop: 16,
      color: 'var(--color-white)',
      backgroundColor: 'var(--color-key)',
      width: '90%'
    },
    linkContainer: {
      margin: '24px 24px 0'
    },
    link: {
      textDecoration: 'none',
      color: 'var(--color-key)',
      fontWeight: 600
    },
    footer: {
      marginTop: '32px'
    }
  })
);

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const InitialTemplate: React.FC<Props> = ({
  property,
  status,
  setQueryParams,
  invalidQueryParams,
  saveQueryToCookie,
  getProperty,
  propertyStatus
}) => {
  const styles = useStyles({});
  const history = useHistory();

  moment.updateLocale('ja', {
    weekdaysShort: ['日', '月', '火', '水', '木', '金', '土']
  });
  const date = moment(property.occupyScheduledDate).format('YYYY年MM月DD日(ddd)');

  useEffect(() => {
    // 本人確認(identification)から遷移した場合は既に物件情報がセットされている
    if (propertyStatus !== 'set' && status === 'not_set') {
      const params = new URLSearchParams(history.location.search);
      const allSpaceId = params.get('s') || '';
      const contractId = params.get('c') || '';

      if (!allSpaceId || !contractId) {
        invalidQueryParams();
        return;
      }
      setQueryParams(allSpaceId, contractId);
    }
  }, [propertyStatus, history, setQueryParams, invalidQueryParams, status]);

  useEffect(() => {
    // 本人確認(identification)から遷移した場合は既に物件情報がセットされている
    if (propertyStatus !== 'set' && status === 'query_set') {
      getProperty();
    }
  }, [propertyStatus, status, getProperty]);

  const handleClick = () => {
    saveQueryToCookie();
    history.push(Path.account.signup);
  };

  if (propertyStatus === 'error') {
    return <NotFoundTemplate />;
  }

  return propertyStatus === 'set' ? (
    property.applicationStatus === 'application_request' ? (
      <>
        <BaseStepper step={0} desc hasElectoric={property.hasElectricContract} />
        <div className={styles.infoContainer}>
          <OccupancyLabel
            label={'入居予定の物件'}
            desc={property.buildingName + ' ' + property.propertyName}
            address={property.prefecture + property.city + property.address}
          />
          <OccupancyLabel label={'入居予定日'} desc={propertyStatus === 'set' ? date : '　'} />
          {propertyStatus !== 'set' && <OccupancyLabel label={'電気契約申し込み'} desc={'　'} />}
          {propertyStatus === 'set' && property.hasElectricContract === true && (
            <>
              <OccupancyLabel label={displayPlanLabel} desc={displayPlanName} />
              <p className={styles.caption}>
                <>
                  {displayNameDescriptions.map(description => (
                    <>
                      {description}
                      <br />
                    </>
                  ))}
                  {displayNameCautions.map(caution => (
                    <span>{caution}</span>
                  ))}
                </>
              </p>
            </>
          )}
          {propertyStatus === 'set' && property.hasElectricContract === false && (
            <>
              <OccupancyLabel label={'電気契約申し込み'} desc={'スマモル賃貸サービスのみ申込む'} />
              <p className={styles.caption}>
                各種サービスと併せて、
                <span>
                  CDエナジーの電気「スマモル賃貸プラン」に契約申込希望の場合は、物件の管理会社へご連絡ください。
                </span>
                原則、入居日を過ぎてからスマモル賃貸プランに加入することは出来ません。
                <br />※
                CDエナジーの電気「スマモル賃貸プラン」に加入されない方でも、「スマモル賃貸サービス」へのお申し込みは必要になります。
              </p>
            </>
          )}
        </div>

        <BaseFab
          className={styles.button}
          disabled={propertyStatus !== 'set'}
          variant="extended"
          size="large"
          aria-label="add"
          onClick={handleClick}
        >
          {property.hasElectricContract ? 'プラン申込みへ' : 'サービス申込みへ'}
        </BaseFab>
        <div className={styles.linkContainer}>
          <p className={styles.caption}>
            別途、郵送されるパンフレットでご説明している電気プラン、サービスは
            <a
              className={styles.link}
              href={'https://www.cdedirect.co.jp/pdf/sumamoruchintai_CDED_202404.pdf'}
              target="_blank"
              rel="noopener noreferrer"
            >
              こちら
            </a>
            からご確認いただけます。
          </p>
        </div>
        {/* desktopでのフッター位置保留 */}
        <div className={styles.footer}>
          <Footer />
        </div>
      </>
    ) : (
      <ApplyCompleteScreen isAlready={true} sumamoruInstallAppTypes={property.sumamoruInstallAppTypes} />
    )
  ) : (
    <div className={styles.container}>
      <div className={styles.loadingContainer}>
        <Loading white />
        <p>データ読み込み中です...</p>
      </div>
    </div>
  );
};

export default InitialTemplate;
