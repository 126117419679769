import { ConditionsResultJudgeRule } from '../../enums/common/conditions-result-judge-rule';
import { DetailConditionOperator } from '../../enums/common/detail-condition-operator';
import { ValueOriginType } from '../../enums/common/value-origin-type';
import { ComponentElementCondition } from '../../types/common/item-definition-types';

export const checkComponentElementCondition = (param: {
  conditions?: Array<ComponentElementCondition>;
  inputValues: any;
  applicationParams: any;
  initialValue?: boolean;
}): boolean => {
  const { conditions, inputValues, applicationParams, initialValue } = param;

  if (!conditions) {
    return initialValue || false;
  }

  for (const condition of conditions) {
    const detailCheck = condition.detailConditions.map(detailCondition => {
      // 基準となる値を取得する
      const basisValue = getComponentElementValue({
        inputValues,
        applicationParams,
        valueType: detailCondition.valueType,
        valueKey: detailCondition.valueKey
      });

      // 比較対象となる値を取得する
      const compareValues = detailCondition.targetValues.map(target =>
        getComponentElementValue({
          inputValues,
          applicationParams,
          valueType: target.valueType,
          valueKey: target.valueKey
        })
      );
      switch (detailCondition.operator || DetailConditionOperator.Equal) {
        case DetailConditionOperator.Equal:
          // 比較方法未指定または一致を指定した場合には、比較対象のすべてが基準値と一致いしている場合のみTrueとなる
          return compareValues.every(target => target === basisValue);
        case DetailConditionOperator.NotEqual:
          // 比較方法を不一致を指定した場合には、比較対象のすべてが基準値と一致いしていない場合のみTrueとなる
          return compareValues.every(target => target !== basisValue);
        case DetailConditionOperator.Some:
          // 比較方法を含むを指定した場合には、比較対象のいづれかが基準値と一致いしている場合のみTrueとなる
          return compareValues.some(target => target === basisValue);
        default:
          return true;
      }
    });

    switch (condition.judgeRuleType || ConditionsResultJudgeRule.Every) {
      case ConditionsResultJudgeRule.Every:
        if (detailCheck.some(check => !check)) {
          return false;
        }
        break;
      case ConditionsResultJudgeRule.Some:
        if (detailCheck.every(check => !check)) {
          return false;
        }
        break;
    }
  }
  return true;
};

export const getComponentElementValue = (param: {
  inputValues: any;
  applicationParams: any;
  valueType?: ValueOriginType;
  valueKey: string | boolean | number;
}) => {
  const valueType = param.valueType;
  const valueKey = param.valueKey;

  if (!valueType || typeof valueKey === 'boolean' || valueType === ValueOriginType.Fixed) {
    return valueKey;
  }
  if (valueType === ValueOriginType.InputValues) {
    return param.inputValues[valueKey];
  }
  if (valueType === ValueOriginType.ApplicationParams) {
    // console.log(JSON.stringify(param.applicationParams));
    // console.log(valueKey);
    // console.log(JSON.stringify(param.applicationParams[valueKey]));
    return param.applicationParams[valueKey];
  }
  return valueKey;
};
