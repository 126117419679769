import * as React from 'react';
import { useCallback, useMemo, useState, useContext, useEffect } from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { mailRegex } from '../utils/regex';
import { BaseFormStateContext } from '../BaseForm';
import { ComponentInputElement } from '../../types/common/item-definition-types';
import { ApplyTextField } from '../baseform/ApplyTextField';
import { ItemValueKeySuffix } from '../../enums/common/item-value-key-suffix';
import { OccupancyLabelBox } from '../OccupancyLabel';

interface P {
  componentElement: ComponentInputElement;
}

const useStyles = makeStyles(
  createStyles({
    checkbox: {
      '& .MuiTypography-root': {
        color: 'var(--color-text)',
        fontSize: 14
      },
      '& .MuiCheckbox-root': {
        color: 'var(--color-key)'
      }
    }
  })
);

/**
 *
 * bitkeyアカウントで使用したEmailを使用するか否かを選択可能な
 * Email入力用のコンポーネント
 *
 */
const EmailInputElement: React.FC<P> = ({ componentElement }) => {
  const styles = useStyles({});
  const { inputValues, updateInputValues, applicationParams } = useContext(BaseFormStateContext);

  const valueKey = useMemo(() => componentElement.valueKey, []);
  const isSameAsBitkeyColumn = useMemo(() => `${valueKey}IsSameAsBitkey`, []);
  const inputColumn = useMemo(() => `${valueKey}Input`, []);
  const confirmationColumn = useMemo(() => `${valueKey}Confirmation`, []);
  const inputHasErrorColumn = useMemo(() => inputColumn + ItemValueKeySuffix.HasError, []);
  const confirmationHasErrorColumn = useMemo(() => confirmationColumn + ItemValueKeySuffix.HasError, []);
  const inputIsDisplayedColumn = useMemo(() => inputColumn + ItemValueKeySuffix.IsDisplayed, []);
  const confirmationIsDisplayedColumn = useMemo(() => confirmationColumn + ItemValueKeySuffix.IsDisplayed, []);

  const isSameAsBitkey = useMemo(
    () => (inputValues[isSameAsBitkeyColumn] === undefined ? true : inputValues[isSameAsBitkeyColumn]),
    [inputValues[isSameAsBitkeyColumn]]
  );
  const inputValue = useMemo(() => inputValues[inputColumn] || componentElement.initialValue, [
    inputValues[inputColumn]
  ]);
  const confirmationValue = useMemo(() => inputValues[confirmationColumn] || componentElement.initialValue || '', [
    inputValues[confirmationColumn]
  ]);

  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [emailConfErrorMessage, setEmailConfErrorMessage] = useState('');

  const handleEmail = useCallback(
    (email: string) => {
      let error = true;
      if (email === '') {
        setEmailErrorMessage('');
      } else if (email.length > 60) {
        setEmailErrorMessage('60文字以内で入力してください');
      } else if (!email.match(mailRegex)) {
        setEmailErrorMessage('有効なメールアドレスを入力してください');
      } else {
        error = false;
        setEmailErrorMessage('');
      }
      updateInputValues(inputHasErrorColumn, error);
      updateInputValues(inputColumn, email);
      updateInputValues(valueKey, email);
    },
    [updateInputValues, setEmailErrorMessage, inputColumn, inputHasErrorColumn, valueKey, mailRegex]
  );

  const handleEmailConfirmation = useCallback(
    (confirmation: string) => {
      let error = true;
      if (confirmation === '') {
        setEmailConfErrorMessage('');
      } else if (confirmation !== inputValue) {
        setEmailConfErrorMessage('メールアドレスが一致しません');
      } else {
        error = false;
        setEmailConfErrorMessage('');
      }
      updateInputValues(confirmationHasErrorColumn, error);
      updateInputValues(confirmationColumn, confirmation);
    },
    [inputValue]
  );

  useEffect(() => {
    updateInputValues(inputIsDisplayedColumn, !isSameAsBitkey);
    updateInputValues(confirmationIsDisplayedColumn, !isSameAsBitkey);
  }, [isSameAsBitkey]);

  useEffect(() => {
    if (!inputValue && applicationParams['bitkeyEmail']) {
      updateInputValues(valueKey, applicationParams['bitkeyEmail']);
      updateInputValues(inputColumn, applicationParams['bitkeyEmail']);
      updateInputValues(confirmationColumn, applicationParams['bitkeyEmail']);
    }
  }, [applicationParams['bitkeyEmail']]);

  const canUseIsSmaeAsBitkey = useMemo(() => !!applicationParams['bitkeyEmail'], [applicationParams['bitkeyEmail']]);

  useEffect(() => {
    updateInputValues(isSameAsBitkeyColumn, canUseIsSmaeAsBitkey);
  }, [canUseIsSmaeAsBitkey]);

  return (
    <>
      {canUseIsSmaeAsBitkey && (
        <FormControlLabel
          className={styles.checkbox}
          control={
            <Checkbox
              color="primary"
              checked={isSameAsBitkey}
              onChange={e => updateInputValues(isSameAsBitkeyColumn, e.target.checked)}
            />
          }
          label={'Bitkeyアカウントと同じメールアドレスを使用'}
        />
      )}
      {isSameAsBitkey ? (
        <OccupancyLabelBox desc={applicationParams['bitkeyEmail']} />
      ) : (
        <div>
          <ApplyTextField
            placeholder={'メールアドレス'}
            defaultValue={inputValue || ''}
            onBlur={value => handleEmail(value)}
            error={emailErrorMessage !== ''}
            helperText={emailErrorMessage}
          />
          <ApplyTextField
            placeholder={'メールアドレス(確認用)'}
            defaultValue={confirmationValue || ''}
            error={emailConfErrorMessage !== ''}
            helperText={emailConfErrorMessage}
            onBlur={value => handleEmailConfirmation(value)}
          />
        </div>
      )}
    </>
  );
};

export default React.memo(EmailInputElement);
