import React, { FC, useState, useCallback, useMemo } from 'react';
import { Modal, Container, Button, IconButton, Typography, FormGroup } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import { Path } from '../constants/path';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { BaseFab } from './BaseFab';
import { TermsAgreementCheckbox } from './TermsAgreementCheckbox';

const useStyles = makeStyles(
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    paper: {
      backgroundColor: 'var(--color-white)',
      width: '100%',
      margin: '0 20px',
      borderRadius: 10,
      '@media (min-width:768px)': {
        width: '50%'
      }
    },
    paperHeadingContainer: {
      backgroundColor: 'var(--color-key)',
      padding: '20px 16px',
      fontWeight: 300,
      fontStyle: 'normal',
      borderRadius: '10px 10px 0 0',
      position: 'relative'
    },
    paperHeading: {
      color: 'var(--color-white)',
      fontSize: 14,
      letterSpacing: '0.08em',
      margin: 0
    },
    closeIconButton: {
      position: 'absolute',
      top: 6,
      right: 10
    },
    closeIcon: {
      color: 'var(--color-white)'
    },
    agreementButton: {
      backgroundColor: 'var(--color-key)',
      color: 'var(--color-white)',
      boxShadow: 'none',
      width: '100%',
      margin: '0 auto',
      '@media (min-width:768px)': {
        fontSize: '16px',
        letterSpacing: '0.75px',
        fontWeight: '500',
        width: '60%',
        marginTop: '20px'
      }
    },
    cancelButtonContainer: {
      margin: '20px auto',
      textAlign: 'center',
      '@media (min-width:768px)': {
        fontSize: '16px',
        letterSpacing: '0.75px',
        fontWeight: '500',
        width: '60%'
      }
    },
    modalShopLabelContainer: {
      backgroundColor: 'var(--color-background-gray)',
      padding: '8px 12px',
      fontSize: 12,
      width: 'calc(100% - 24px)',
      margin: '16px 12px'
    },
    modalShopLabel: {
      margin: 0,
      color: 'var(--color-gray-3)',
      fontWeight: 500
    },
    modalShopDetails: {
      color: 'var(--color-gray-1)',
      lineHeight: '17px',
      margin: '8px 0 0',
      '@media (min-width:768px)': {
        marginTop: 0
      }
    },
    containerRoot: {
      paddingTop: 16
    },
    termsContainer: {
      height: 300,
      overflowY: 'scroll'
    },
    terms: {
      margin: 0,
      fontSize: 12,
      color: 'var(--color-text)',
      lineHeight: '120%',
      // height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    bottomContainer: {
      textAlign: 'center'
    },
    termsForm: {
      height: 'calc(100% - 80px)',
      justifyContent: 'center',
      paddingLeft: 16
    },
    additionalExplanation: {
      color: 'var(--color-error)'
    },
    confirms: {
      margin: 16
    }
  })
);

interface Props {
  modalOpen: boolean;
  setModalOpen: (e: boolean) => void;
  management_company_name: string;
  management_company_address: string;
  management_company_phonenumber: string;
  hasElectricContract: boolean;
  useGasPlanSelection: boolean;
}

type SumamoruExplanationMattersChecked = {
  electricContract: boolean;
  privacyPolicy: boolean;
  sumamoruPlanOutline: boolean;
};

type GasExplanationMattersChecked = {
  gasSupplymentOutline: boolean;
  privacyPolicy: boolean;
};

type TermsAgreement<T> = {
  label: string;
  type: keyof T;
  link: string;
};

const ApplicationModal: FC<Props> = ({ modalOpen, setModalOpen, hasElectricContract, useGasPlanSelection }) => {
  const styles = useStyles();
  const history = useHistory();

  const [firstStepDone, setFirstStepDone] = useState(false);

  // スマモル重要事項説明画面に関する設定
  const [sumamoruChecked, setSumamoruChecked] = useState<SumamoruExplanationMattersChecked>({
    electricContract: false,
    privacyPolicy: false,
    sumamoruPlanOutline: false
  });

  const sumamoruTerms = useCallback((electricContract: boolean): TermsAgreement<
    SumamoruExplanationMattersChecked
  >[] => {
    const terms: TermsAgreement<SumamoruExplanationMattersChecked>[] = [
      {
        type: 'privacyPolicy',
        label: 'お客さま情報の取り扱いについて',
        link: 'https://www.cdedirect.co.jp/pdf/privacy.pdf'
      },
      {
        type: 'sumamoruPlanOutline',
        label: 'スマモル賃貸プラン　重要事項説明書',
        link: 'https://www.cdedirect.co.jp/assets/pdf/agreement/denki/juuyou_sumamoru.pdf'
      }
    ];

    if (!electricContract) {
      return terms;
    }

    return [
      {
        type: 'electricContract',
        label: '電気需給契約　重要事項説明書',
        link: 'https://www.cdedirect.co.jp/assets/pdf/agreement/denki/juuyou_denki.pdf'
      },
      ...terms
    ];
  }, []);

  const onSumamoruCheck = useCallback(
    (type: keyof SumamoruExplanationMattersChecked) => (check: boolean) => {
      setSumamoruChecked(prev => ({
        ...prev,
        [type]: check
      }));
    },
    []
  );

  // ガスの重要事項説明画面に関するステート
  const [gasChecked, setGasChecked] = useState<GasExplanationMattersChecked>({
    gasSupplymentOutline: false,
    privacyPolicy: false
  });

  const gasTerms = useMemo((): TermsAgreement<GasExplanationMattersChecked>[] => {
    return [
      {
        type: 'gasSupplymentOutline',
        label: 'ガス需給契約　重要事項説明書',
        link: 'https://www.cdedirect.co.jp/assets/pdf/agreement/gas/juuyou_gas.pdf'
      },
      {
        type: 'privacyPolicy',
        label: 'お客さま情報の取り扱いについて',
        link: 'https://www.cdedirect.co.jp/pdf/privacy.pdf'
      }
    ];
  }, []);

  const onGasCheck = useCallback(
    (type: keyof GasExplanationMattersChecked) => (check: boolean) => {
      setGasChecked(prev => ({
        ...prev,
        [type]: check
      }));
    },
    []
  );

  const canGoToConfirm = useCallback((): boolean => {
    if (!firstStepDone) {
      return (
        (!hasElectricContract || sumamoruChecked.electricContract) &&
        sumamoruChecked.privacyPolicy &&
        sumamoruChecked.sumamoruPlanOutline
      );
    }

    return gasChecked.gasSupplymentOutline && gasChecked.privacyPolicy;
  }, [firstStepDone, sumamoruChecked, gasChecked]);

  const handleModalClose = () => {
    setModalOpen(false);
    setFirstStepDone(false);
    setSumamoruChecked({
      electricContract: false,
      privacyPolicy: false,
      sumamoruPlanOutline: false
    });
    setGasChecked({
      gasSupplymentOutline: false,
      privacyPolicy: false
    });
  };

  const handleClick = () => {
    if (!useGasPlanSelection || (useGasPlanSelection && firstStepDone)) {
      history.push(Path.application.confirm);
      return;
    }

    setFirstStepDone(true);
  };

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={modalOpen}
      className={styles.modal}
      onClose={() => setModalOpen(false)}
    >
      <div className={styles.paper}>
        {!firstStepDone ? (
          <>
            <Container className={styles.paperHeadingContainer}>
              <h2 className={styles.paperHeading}>スマモル賃貸プラン 電気契約の重要事項説明</h2>
              <IconButton className={styles.closeIconButton} onClick={handleModalClose}>
                <CloseIcon className={styles.closeIcon} />
              </IconButton>
            </Container>

            <Container className={styles.containerRoot}>
              <div className={styles.termsContainer}>
                <div id={'terms'} className={styles.terms}>
                  <div className={styles.confirms}>
                    <Typography>
                      以下{sumamoruTerms(hasElectricContract).length}つのリンク先をご確認ください。
                    </Typography>
                    <Typography>
                      内容に問題がなければ、画面下部のボタンから申し込み内容の確認へお進みください。
                    </Typography>
                  </div>
                  <FormGroup className={styles.termsForm}>
                    {sumamoruTerms(hasElectricContract).map((term, index) => (
                      <div key={index}>
                        <TermsAgreementCheckbox
                          checked={sumamoruChecked[term.type]}
                          onCheck={onSumamoruCheck(term.type)}
                          label={term.label}
                          link={term.link}
                        />
                      </div>
                    ))}
                  </FormGroup>
                  <div className={styles.confirms}>
                    <Typography>
                      {
                        'ＣＤエナジーダイレクトは、電気事業法に定める契約締結前及び契約締結後の書面交付について、書面でお知らせする事項を除いては、書面交付に代えて、重要事項説明書および電気個別要綱のPDFファイルを当社ホームページに掲載する方法によりこれを提供します。（閲覧には最新版のPDF閲覧用ソフトをご利用ください）'
                      }
                    </Typography>
                  </div>
                </div>
              </div>
            </Container>

            <Container className={styles.bottomContainer}>
              <BaseFab
                className={styles.agreementButton}
                variant="extended"
                size="large"
                aria-label="add"
                disabled={!canGoToConfirm()}
                onClick={handleClick}
              >
                {useGasPlanSelection ? 'ガスの重要説明事項の確認へ' : '同意して申込内容の確認へ'}
              </BaseFab>
              <div className={styles.cancelButtonContainer}>
                <Button onClick={handleModalClose}>キャンセル</Button>
              </div>
            </Container>
          </>
        ) : (
          <>
            <Container className={styles.paperHeadingContainer}>
              <h2 className={styles.paperHeading}>ガスの重要事項説明</h2>
              <IconButton className={styles.closeIconButton} onClick={handleModalClose}>
                <CloseIcon className={styles.closeIcon} />
              </IconButton>
            </Container>

            <Container className={styles.containerRoot}>
              <div className={styles.termsContainer}>
                <div id={'terms'} className={styles.terms}>
                  <div className={styles.confirms}>
                    <Typography>以下{gasTerms.length}つのリンク先をご確認ください。</Typography>
                    <Typography>
                      内容に問題がなければ、画面下部のボタンから申し込み内容の確認へお進みください。
                    </Typography>
                  </div>
                  <FormGroup className={styles.termsForm}>
                    {gasTerms.map((term, index) => (
                      <div key={index}>
                        <TermsAgreementCheckbox
                          checked={gasChecked[term.type]}
                          onCheck={onGasCheck(term.type)}
                          label={term.label}
                          link={term.link}
                        />
                      </div>
                    ))}
                  </FormGroup>
                  <div className={styles.confirms}>
                    <Typography>
                      {
                        'ＣＤエナジーダイレクトは、ガス事業法に定める契約締結前及び契約締結後の書面交付について、書面でお知らせする事項を除いては、書面交付に代えて、重要事項説明書およびガス個別要綱のPDFファイルを当社ホームページに掲載する方法によりこれを提供します。（閲覧には最新版のPDF閲覧用ソフトをご利用ください）'
                      }
                    </Typography>
                  </div>
                </div>
              </div>
            </Container>

            <Container className={styles.bottomContainer}>
              <BaseFab
                className={styles.agreementButton}
                variant="extended"
                size="large"
                aria-label="add"
                disabled={!canGoToConfirm()}
                onClick={handleClick}
              >
                同意して申込内容の確認へ
              </BaseFab>
              <div className={styles.cancelButtonContainer}>
                <Button onClick={handleModalClose}>キャンセル</Button>
              </div>
            </Container>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ApplicationModal;
