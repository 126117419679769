import * as React from 'react';
import { useMemo, useContext, useEffect } from 'react';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { getComponentElementKeyValue } from '../utils/key-value-getter';
import { BaseFormStateContext } from '../BaseForm';
import { ComponentInputElement } from '../../types/common/item-definition-types';

interface P {
  componentElement: ComponentInputElement;
}

const useStyles = makeStyles(
  createStyles({
    selectPlaceholder: {
      color: '#BDBDBD'
    }
  })
);

const SelectItemElement: React.FC<P> = ({ componentElement }) => {
  const styles = useStyles({});
  const { inputValues, updateInputValues, applicationParams } = useContext(BaseFormStateContext);

  const valueKey = useMemo(() => componentElement.valueKey, []);

  const inputValue = useMemo(
    () =>
      getComponentElementKeyValue({
        inputValues,
        applicationParams,
        inputValueType: componentElement.valueType,
        valueKey: valueKey,
        initialValue: componentElement.initialValue,
        converter: componentElement.converter
      }),
    [
      inputValues[valueKey],
      applicationParams[valueKey],
      componentElement.valueType,
      valueKey,
      componentElement.initialValue
    ]
  );

  // 初期値の補完
  useEffect(() => {
    if (inputValues[valueKey] || !componentElement.initialValue) {
      return;
    }
    updateInputValues(valueKey, componentElement.initialValue);
  }, []);

  return (
    <FormControl margin="normal" variant="outlined" style={{ ...componentElement.styleOption, width: '100%' }}>
      <Select
        value={inputValue}
        onChange={e => updateInputValues(valueKey, e.target.value)}
        displayEmpty
        placeholder={componentElement.placeholder}
      >
        {!componentElement.initialValue && componentElement.placeholder && (
          <MenuItem value="" disabled>
            <span className={styles.selectPlaceholder}>{componentElement.placeholder}</span>
          </MenuItem>
        )}
        {componentElement.selectionItems &&
          componentElement.selectionItems.map(item => (
            <MenuItem value={item.value} key={`select-${valueKey}-${item.value}`}>
              {item.label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default React.memo(SelectItemElement);
