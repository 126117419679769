import { ThunkAction } from 'redux-thunk';
import * as Actions from './actions';
import { AnyAction } from 'redux';
import { AppState } from '../../store';
import { bitlockVoApiPath, bitlockVoApiKey } from '../../config/baseConfig';
import { PrefectureJPMap } from '../../utils/address-types';
import { Property } from './types';
import BaseApplicationConditionService from '../../services/base-application-condition-service';

export const setIdsFromCookie = (): ThunkAction<{}, {}, {}, AnyAction> => async dispatch => {
  const cookies = document.cookie.split('; ');

  for (const cookie of cookies) {
    const c = cookie.split('=');

    switch (c[0]) {
      case 'all_space_id': {
        dispatch(Actions.setAllSpaceId(c[1]));
        break;
      }
      case 'contract_id': {
        dispatch(Actions.setContractId(c[1]));
        break;
      }
      default: {
        break;
      }
    }
  }
};

export const getProperty = (): ThunkAction<Promise<void>, AppState, {}, AnyAction> => async (dispatch, getState) => {
  try {
    dispatch(Actions.startToFetchProperty());
    const { allSpaceId, contractId } = getState().property.property;

    if (allSpaceId === '' || contractId === '') {
      dispatch(Actions.failedSetProperty());
      return;
    }

    const baseApplicationCondition = await BaseApplicationConditionService.loadBaseApplicationCondition(
      allSpaceId,
      contractId
    );

    dispatch(
      Actions.setProperty({
        allSpaceId: allSpaceId,
        contractId: contractId,
        applicationStatus: baseApplicationCondition.applicationStatus,
        hasElectricContract: baseApplicationCondition.hasElectricContract,
        occupyScheduledDate: new Date(baseApplicationCondition.occupyScheduledDate),
        postCode: baseApplicationCondition.postCode,
        address: baseApplicationCondition.address,
        prefecture: PrefectureJPMap[baseApplicationCondition.prefecture],
        city: baseApplicationCondition.city,
        afterCity: baseApplicationCondition.afterCity,
        buildingName: baseApplicationCondition.buildingName,
        propertyName: baseApplicationCondition.name,
        propertyCode: baseApplicationCondition.code,
        sumamoruCode: baseApplicationCondition.sumamoruCode,
        sumamoruPlan: baseApplicationCondition.sumamoruPlan, 
        sumamoruProjectType: baseApplicationCondition.sumamoruProjectType,
        status: baseApplicationCondition.status,
        managementCompanyAddress: baseApplicationCondition.managementCompanyAddress,
        managementCompanyName: baseApplicationCondition.managementCompanyName,
        managementCompanyPhoneNumber: baseApplicationCondition.managementCompanyPhoneNumber,
        sumamoruInstallAppTypes: baseApplicationCondition.sumamoruInstallAppTypes
      })
    );
  } catch (error) {
    console.error(error);
    dispatch(Actions.failedSetProperty());
  }
};
