import * as React from 'react';
import { ComponentInputElement } from '../../types/common/item-definition-types';
import { ComponentType } from '../../enums/common/component-type';

import RadioGroupComponent from './RadioGroupComponent';
import FixedTextElement from './FixedTextElement';
import KeyboardDateElement from './KeyboardDateElement';
import SelectItemElement from './SelectItemElement';
import TextFeildElement from './TextFeildElement';
import SelectDateElement from './SelectDateElement';

import EmailInputElement from './EmailInputElement';
import KanaNameAutoCompleteInputElement from './KanaNameAutoCompleteInputElement';

interface P {
  componentElement: ComponentInputElement;
}

const InputComponentElement: React.FC<P> = ({ componentElement }) => {
  if (!componentElement.inputType) {
    return <div></div>;
  }
  switch (componentElement.inputType) {
    case ComponentType.RadioButtonInput:
      return <RadioGroupComponent componentElement={componentElement} />;
    case ComponentType.FixedText:
      return <FixedTextElement componentElement={componentElement} />;
    case ComponentType.KeyboardDateInput:
      return <KeyboardDateElement componentElement={componentElement} />;
    case ComponentType.SelectItemInput:
      return <SelectItemElement componentElement={componentElement} />;
    case ComponentType.TextFieldInput:
      return <TextFeildElement componentElement={componentElement} />;
    case ComponentType.SelectDateInput:
      return <SelectDateElement componentElement={componentElement} />;
    case ComponentType.EmailInput:
      return <EmailInputElement componentElement={componentElement} />;
    case ComponentType.KanaNameAutoCompleteInput:
      return <KanaNameAutoCompleteInputElement componentElement={componentElement} />;
    default:
      return <div></div>;
  }
};

export default React.memo(InputComponentElement);
